import * as React from 'react';

import {
  renderTranslationString,
  TranslationString
} from 'shared/entities/localization';
import { TBankIcon } from 'shared/newComponents/icons';
import { blockStaticConfigMap } from 'shared/SmartGraph/blockStaticConfigMap';

import {
  mapUserPaymentMethodKindEntities,
  UserPaymentMethodKind
} from '../userPayment';
import { CommonIconProps } from '../components/Icon';
import { documentationLinks } from '../app';
import { BlockKind } from '../block/common';

export enum IntegrationType {
  google = 'google',
  domains = 'domains',
  api = 'api',
  getCourse = 'get_course',
  amoCRM = 'amocrm',
  prodamus = 'prodamus'
}

export enum IntegrationTypeAll {
  all = 'all'
}

export type IntegrationTab = UserPaymentMethodKind | IntegrationType;

export type IntegrationUnit = IntegrationTab | IntegrationTypeAll;

export type IntegrationTabType = {
  id: IntegrationTab;
  title: TranslationString | string;
  entitiesTitle: TranslationString;
  isNew?: boolean;
  isBeta?: boolean;
  onlyForSa?: boolean;
  fullIcon?: React.FC<CommonIconProps>;
  documentationLink: string;
  description?: TranslationString;
};

export const mapIntegrationTabToConfig: Record<
  IntegrationTab,
  IntegrationTabType
> = {
  [UserPaymentMethodKind.yookassa]: {
    id: UserPaymentMethodKind.yookassa,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yookassa].title,
    entitiesTitle: (t) =>
      t('integrations.yookassa.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.yookassa,
    description: (t) =>
      t('integrations.yookassa.description', {
        ns: 'entities',
        title:
          mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yookassa].title
      })
  },
  [UserPaymentMethodKind.qiwi]: {
    id: UserPaymentMethodKind.qiwi,
    title: mapUserPaymentMethodKindEntities[UserPaymentMethodKind.qiwi].title,
    entitiesTitle: (t) =>
      t('integrations.qiwi.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.paymentSystemConnection,
    description: (t) =>
      t('integrations.qiwi.description', {
        ns: 'entities',
        title:
          mapUserPaymentMethodKindEntities[UserPaymentMethodKind.qiwi].title
      })
  },
  [UserPaymentMethodKind.yooMoney]: {
    id: UserPaymentMethodKind.yooMoney,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yooMoney].title,
    entitiesTitle: (t) =>
      t('integrations.yooMoney.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.paymentSystemConnection,
    description: (t) =>
      t('integrations.yooMoney.description', {
        ns: 'entities',
        title:
          mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yooMoney].title
      })
  },
  [UserPaymentMethodKind.tg]: {
    id: UserPaymentMethodKind.tg,
    title: mapUserPaymentMethodKindEntities[UserPaymentMethodKind.tg].title,
    entitiesTitle: (t) =>
      t('integrations.tg.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.paymentSystemConnection,
    description: (t) =>
      t('integrations.tg.description', {
        ns: 'entities',
        title: mapUserPaymentMethodKindEntities[UserPaymentMethodKind.tg].title
      })
  },
  [UserPaymentMethodKind.prodamus]: {
    id: UserPaymentMethodKind.prodamus,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.prodamus].title,
    entitiesTitle: (t) =>
      t('integrations.prodamus.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.paymentSystemConnection,
    description: (t) =>
      t('integrations.prodamus.description', { ns: 'entities' })
  },
  [UserPaymentMethodKind.tinkoff]: {
    id: UserPaymentMethodKind.tinkoff,
    title:
      mapUserPaymentMethodKindEntities[UserPaymentMethodKind.tinkoff].title,
    entitiesTitle: (t) =>
      t('integrations.tinkoff.entitiesTitle', {
        ns: 'entities'
      }),
    fullIcon: TBankIcon,
    documentationLink: documentationLinks.tinkoffPayment,
    description: (t) =>
      t('integrations.tinkoff.description', { ns: 'entities' })
  },
  [IntegrationType.google]: {
    id: IntegrationType.google,
    title: (t) =>
      t('integrations.google.title', {
        ns: 'entities'
      }),
    entitiesTitle: (t) =>
      t('integrations.google.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.googleTables,
    description: (t) => t('integrations.google.description', { ns: 'entities' })
  },
  [IntegrationType.domains]: {
    id: IntegrationType.domains,
    title: (t) =>
      t('integrations.domains.title', {
        ns: 'entities'
      }),
    entitiesTitle: (t) =>
      t('integrations.domains.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.httpRequest,
    description: (t) =>
      t('integrations.domains.description', { ns: 'entities' })
  },
  [IntegrationType.api]: {
    id: IntegrationType.api,
    title: 'API Smartbot Pro',
    entitiesTitle: (t) =>
      t('integrations.api.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.webhook,
    description: (t) =>
      t('integrations.api.description', {
        ns: 'entities',
        blockName: renderTranslationString(
          blockStaticConfigMap[BlockKind.webhook].name,
          t
        )
      })
  },
  [IntegrationType.getCourse]: {
    id: IntegrationType.getCourse,
    title: 'GetCourse',
    entitiesTitle: (t) =>
      t('integrations.getCourse.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.getCourse,
    description: (t) =>
      t('integrations.getCourse.description', {
        ns: 'entities'
      })
  },
  [IntegrationType.amoCRM]: {
    id: IntegrationType.amoCRM,
    title: 'amoCRM',
    entitiesTitle: (t) =>
      t('integrations.amoCRM.entitiesTitle', {
        ns: 'entities'
      }),
    documentationLink: documentationLinks.amoCRM,
    description: (t) => t('integrations.amoCRM.description', { ns: 'entities' })
  }
};

export const integrationTabsOrder: IntegrationTab[] = [
  UserPaymentMethodKind.yookassa,
  UserPaymentMethodKind.yooMoney,
  UserPaymentMethodKind.qiwi,
  UserPaymentMethodKind.tg,
  UserPaymentMethodKind.prodamus,
  UserPaymentMethodKind.tinkoff,
  IntegrationType.getCourse,
  IntegrationType.amoCRM,
  IntegrationType.google,
  IntegrationType.domains,
  IntegrationType.api
];

export enum TinkoffTerminalCreationError {
  useAnotherTinkoffTerminal = 'use_another_tinkoff_terminal'
}

export enum ActionPlace {
  stub = 'stub',
  header = 'header'
}

export type ActionProps = {
  place: ActionPlace;
};

export const getIntegrationDescription = (
  type: IntegrationTab
): TranslationString | null => {
  switch (type) {
    case IntegrationType.domains:
      return (t) => t('integrations.domains.description', { ns: 'entities' });
    case IntegrationType.amoCRM:
      return (t) => t('integrations.amoCRM.description', { ns: 'entities' });
    case IntegrationType.api:
      return (t) =>
        t('integrations.api.description', {
          ns: 'entities',
          blockName: renderTranslationString(
            blockStaticConfigMap[BlockKind.webhook].name,
            t
          )
        });
    case IntegrationType.google:
      return (t) => t('integrations.google.description', { ns: 'entities' });
    case UserPaymentMethodKind.tinkoff:
      return (t) => t('integrations.tinkoff.description', { ns: 'entities' });
    case UserPaymentMethodKind.prodamus:
      return (t) => t('integrations.prodamus.description', { ns: 'entities' });
    case UserPaymentMethodKind.tg:
      return (t) =>
        t('integrations.tg.description', {
          ns: 'entities',
          title:
            mapUserPaymentMethodKindEntities[UserPaymentMethodKind.tg].title
        });
    case UserPaymentMethodKind.yooMoney:
      return (t) =>
        t('integrations.yooMoney.description', {
          ns: 'entities',
          title:
            mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yooMoney]
              .title
        });
    case UserPaymentMethodKind.yookassa:
      return (t) =>
        t('integrations.yookassa.description', {
          ns: 'entities',
          title:
            mapUserPaymentMethodKindEntities[UserPaymentMethodKind.yookassa]
              .title
        });
    case UserPaymentMethodKind.qiwi:
      return (t) =>
        t('integrations.qiwi.description', {
          ns: 'entities',
          title:
            mapUserPaymentMethodKindEntities[UserPaymentMethodKind.qiwi].title
        });
    default:
      return null;
  }
};
