import * as React from 'react';

import { IconComponent, IconSize } from 'shared/entities/components/Icon';
import { StatusType } from 'shared/newEntities/components/Status';
import {
  AlertIcon,
  CheckMarkIcon,
  CircledExclamationMarkIcon
} from 'shared/newComponents/icons';

import './Status.modules.scss';

const mapTypeToIcon: Record<StatusType, IconComponent> = {
  [StatusType.success]: CheckMarkIcon,
  [StatusType.warning]: AlertIcon,
  [StatusType.error]: CircledExclamationMarkIcon
};

type Props = {
  type: StatusType;
  iconSize?: IconSize;
};

const Status: React.FC<Props> = ({ type, iconSize = IconSize.L }) => {
  const Icon = mapTypeToIcon[type];

  return <Icon styleName={`status_${type}`} iconSize={iconSize} />;
};

export default React.memo(Status);
