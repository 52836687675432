import * as React from 'react';
import { observer } from 'mobx-react';

import Section from 'shared/newComponents/Section';
import Stub from 'shared/newComponents/Stub';
import { useChannelsModalStore } from 'stores/ChannelsModalStore';
import { useChannelsStore } from 'stores';
import { LoadingStage } from 'shared/entities/meta';
import LoaderSpinner from 'shared/newComponents/animations/LoaderSpinner';
import {
  LoaderSpinnerColor,
  LoaderSpinnerSize
} from 'shared/newEntities/components/LoaderSpinner';

import './Content.modules.scss';

import Header from './Header';
import ChannelTable from './ChannelTable';

type Props = {
  className?: string;
  footer?: React.ReactNode;
};

const Content: React.FC<Props> = ({ className, footer }) => {
  const channelsStore = useChannelsStore();
  const { store } = useChannelsModalStore();

  const isEmpty = !channelsStore.channels.length;

  const loading =
    channelsStore.channels.loadingStage === LoadingStage.LOADING ||
    channelsStore.channels.loadingStage === LoadingStage.NOT_STARTED;

  return (
    <Section className={className}>
      <div styleName="container">
        {(!!store.withSections || store.scenario) && (
          <Header styleName="header" />
        )}
        <div styleName="list">
          {loading && isEmpty && (
            <LoaderSpinner
              size={LoaderSpinnerSize.sm}
              color={LoaderSpinnerColor.blue}
              centered
            />
          )}
          {!loading && isEmpty && (
            <Stub
              title={(t) =>
                t('ChannelsModal.Content.Stub.title', {
                  ns: 'newContainers'
                })
              }
              description={(t) =>
                t('ChannelsModal.Content.Stub.description', {
                  ns: 'newContainers'
                })
              }
            />
          )}
          {!isEmpty && <ChannelTable footer={footer} />}
        </div>
      </div>
    </Section>
  );
};

export default observer(Content);
