import { Centrifuge } from 'centrifuge';
import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  when
} from 'mobx';

import { IWebSocketStore } from 'shared/entities/store/webSocketStore';
import { IRootStore } from 'shared/entities/store/rootStore';
import { LoadingStageModel } from 'shared/models/loadingStage';
import { apiUrls, WS_DOMAIN } from 'shared/entities/domain';
import { localStorageHandler } from 'stores/localStorageHandler';
import { createWsAuthTokenKey } from 'shared/entities/localStorage';
import {
  PubSubWsEvent,
  PubSubWsEventSubscriptionRemoveData,
  QueueChannel,
  WsConnectionState,
  WsSubscriptionListener
} from 'shared/entities/ws';
import { FieldModel } from 'shared/models/form';
import ListModel from 'shared/models/ListModel';
import { WsSubscription } from 'shared/models/ws';
import PubSubObserver from 'lib/PubSubObserver';

export class WebSocketStore extends PubSubObserver implements IWebSocketStore {
  private rootStore: IRootStore;
  private _client: Centrifuge | null = null;
  readonly state: FieldModel<WsConnectionState> =
    new FieldModel<WsConnectionState>(WsConnectionState.disconnected);
  private subscribedToStateEvents: FieldModel<boolean> =
    new FieldModel<boolean>(false);
  readonly subscriptions: ListModel<WsSubscription> =
    new ListModel<WsSubscription>();
  readonly obtainTokensStage: LoadingStageModel = new LoadingStageModel();
  // очередь для хранения канал + слушателей, на которые хотим подписаться
  readonly queueChannels: ListModel<QueueChannel> =
    new ListModel<QueueChannel>();
  readonly authToken: FieldModel<string | null> = new FieldModel<string | null>(
    null
  );

  readonly resetStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    super();

    this.rootStore = rootStore;

    this.handleConnectingEvent = this.handleConnectingEvent.bind(this);
    this.handleConnectedEvent = this.handleConnectedEvent.bind(this);
    this.handleDisconnectedEvent = this.handleDisconnectedEvent.bind(this);
    this.handleChannelFromQueue = this.handleChannelFromQueue.bind(this);
    this.handleSubscriptionDelete = this.handleSubscriptionDelete.bind(this);

    makeObservable<WebSocketStore, '_client' | 'changeClient'>(this, {
      _client: observable.ref,
      queueChannels: observable,

      client: computed,

      changeClient: action
    });
  }

  get client(): Centrifuge | null {
    return this._client;
  }

  initializeReactions(): void {
    this.addReaction({
      key: 'required values for subscription',
      reaction: reaction(() => this.client, this.handleChannelFromQueue)
    });

    this.addReaction({
      key: 'queueChannels length',
      reaction: reaction(
        () => this.queueChannels.length,
        this.handleChannelFromQueue
      )
    });

    this.addReaction({
      key: 'projectId',
      reaction: reaction(() => this.rootStore.projectId, this.reset)
    });

    this.subscribeEvent(
      PubSubWsEvent.subscriptionRemove,
      this.handleSubscriptionDelete
    );
  }

  async getAuthToken(refresh = false): Promise<BaseResponse<string>> {
    if (this.authToken.value && !refresh) {
      return { isError: false, data: this.authToken.value };
    }

    if (!refresh) {
      const response = this.getTokensFromLS();

      if (!response.isError) {
        this.authToken.changeValue(response.data);
        return response;
      }
    }

    const obtainTokenResponse = await this.obtainToken();

    if (!obtainTokenResponse.isError) {
      this.authToken.changeValue(obtainTokenResponse.data);
    }

    return obtainTokenResponse;
  }

  /**
   * Получает новый токен(не смотрит в localStorage)
   * @returns {Promise<string>}
   */
  getAuthTokenViaRefresh = async (): Promise<string> => {
    const tokenResponse = await this.getAuthToken(true);

    return !tokenResponse.isError ? tokenResponse.data : '';
  };

  async connect(): Promise<BaseResponse> {
    await when(() => !this.resetStage.isLoading);

    if (this.client) {
      if (this.state.value === WsConnectionState.disconnected) {
        this.client.connect();
      }

      return {
        isError: false
      };
    }

    const tokensResponse = await this.getAuthToken();

    if (tokensResponse.isError) {
      return {
        isError: true
      };
    }

    this.changeClient(
      new Centrifuge(`wss://${WS_DOMAIN}/connection/websocket`, {
        token: tokensResponse.data,
        getToken: this.getAuthTokenViaRefresh
      })
    );

    this.subscribeToStateEvents();

    this._client?.connect();

    return {
      isError: false
    };
  }

  private disconnect(): void {
    if (!this.client) {
      return;
    }
    this.client.disconnect();
    this.changeClient(null);
  }

  unsubscribeFromChannel(channel: string): void {
    const subscription = this.subscriptions.getEntity(channel);

    if (!subscription) {
      return;
    }

    subscription.unsubscribe();
  }

  subscribeToChannel({
    key,
    listeners = [],
    subscriptionToken
  }: {
    key: string;
    listeners: WsSubscriptionListener[];
    subscriptionToken: string;
  }): void {
    const existingQueueChannel = this.queueChannels.getEntity(key);

    if (existingQueueChannel) {
      listeners.forEach((listener) => {
        if (!existingQueueChannel.listeners.includes(listener)) {
          existingQueueChannel.listeners.push(listener);
        }
      });

      return;
    }

    this.queueChannels.addEntity({
      entity: {
        key,
        listeners,
        subscriptionToken
      },
      key
    });
  }

  deleteSubscription(channel: string): void {
    const subscription = this.subscriptions.getEntity(channel);

    if (!subscription) {
      return;
    }

    subscription.requestToDelete();
  }

  reset = async (): Promise<void> => {
    if (this.resetStage.isLoading) {
      return;
    }

    this.resetStage.loading();
    this.queueChannels.reset();

    this.unsubscribeAllChannels();
    await when(() => this.subscriptions.length === 0);
    this.disconnect();

    this.resetStage.success();
  };

  private handleChannelFromQueue(): void {
    if (!this.client || !this.queueChannels.length) {
      return;
    }

    const key = this.queueChannels.keys.shift();

    if (!key) {
      return;
    }

    const channel = this.queueChannels.getEntity(key);

    if (!channel) {
      return;
    }

    const existingSub = this.subscriptions.getEntity(channel.key);

    if (existingSub) {
      existingSub.addListeners(channel.listeners);
      existingSub.subscribe();

      return;
    }

    const wsSubscription = new WsSubscription({
      channel: channel.key,
      subscription: this.client.newSubscription(channel.key, {
        token: channel.subscriptionToken
      }),
      listeners: channel.listeners
    });

    this.subscriptions.addEntity({
      entity: wsSubscription,
      key: channel.key
    });

    wsSubscription.subscribe();
  }

  private changeClient(client: Centrifuge | null): void {
    this._client = client;
  }

  private subscribeToStateEvents(): BaseResponse {
    if (!this.client || this.subscribedToStateEvents.value) {
      return {
        isError: true
      };
    }

    this.client.on(WsConnectionState.connecting, this.handleConnectingEvent);
    this.client.on(WsConnectionState.connected, this.handleConnectedEvent);
    this.client.on(
      WsConnectionState.disconnected,
      this.handleDisconnectedEvent
    );

    this.subscribedToStateEvents.changeValue(true);

    return {
      isError: false
    };
  }

  private handleConnectingEvent() {
    this.state.changeValue(WsConnectionState.connecting);
  }

  private handleConnectedEvent() {
    this.state.changeValue(WsConnectionState.connected);
  }

  private handleDisconnectedEvent() {
    this.state.changeValue(WsConnectionState.disconnected);
  }

  private handleSubscriptionDelete(
    _,
    data: PubSubWsEventSubscriptionRemoveData
  ) {
    this.subscriptions.removeEntity(data.channel);
  }

  private unsubscribeAllChannels() {
    this.subscriptions.keys.forEach((channel) => {
      this.deleteSubscription(channel);
    });
  }

  private writeTokensToLS(
    token: string,
    projectId: string,
    userId: string
  ): void {
    localStorageHandler.setItem(createWsAuthTokenKey(projectId, userId), token);
  }

  private getTokensFromLS(): BaseResponse<string> {
    if (!this.rootStore.projectId || !this.rootStore.userStore.user?.id) {
      return {
        isError: true
      };
    }

    const authToken = localStorageHandler.getItem(
      createWsAuthTokenKey(
        this.rootStore.projectId,
        this.rootStore.userStore.user.id
      )
    );

    if (!authToken) {
      return {
        isError: true
      };
    }

    return {
      isError: false,
      data: authToken
    };
  }

  private async obtainToken(): Promise<BaseResponse<string>> {
    if (
      this.obtainTokensStage.isLoading ||
      !this.rootStore.projectId ||
      !this.rootStore.userStore.user?.id
    ) {
      return {
        isError: true
      };
    }

    this.obtainTokensStage.loading();

    const response = await this.rootStore.networkStore.api<{
      auth_token: string;
    }>(apiUrls.AUTH_OBTAIN_WS_AUTH_TOKEN);

    if (!response.isError) {
      this.obtainTokensStage.success();

      this.writeTokensToLS(
        response.data.auth_token,
        this.rootStore.projectId,
        this.rootStore.userStore.user.id
      );

      return {
        isError: false,
        data: response.data.auth_token
      };
    }

    this.obtainTokensStage.error();

    return {
      isError: true
    };
  }
}
