import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction
} from 'mobx';

import { ProjectModel } from 'shared/models/project';
import { SmartIds } from 'shared/entities/network';
import { apiUrls, urls } from 'shared/entities/domain';
import { IRootStore } from 'shared/entities/store/rootStore';
import { IProjectStore } from 'shared/entities/store/projectStore';
import {
  GetProjectServer,
  IProjectOptions,
  mapBaseErrorCodeToProjectErrorToMessage,
  ProjectFeatures
} from 'shared/entities/projects';
import { LocalStorageKey } from 'shared/entities/localStorage';
import { LoadingStageModel } from 'shared/models/loadingStage';

import { localStorageHandler } from '../localStorageHandler';

export default class ProjectStore implements IProjectStore {
  private _rootStore: IRootStore;

  private _entity: ProjectModel | null = null;

  private _options: IProjectOptions | null = null;

  private _features: Record<ProjectFeatures, boolean> | {} = {};

  readonly loadingStage: LoadingStageModel = new LoadingStageModel();

  readonly removingStage: LoadingStageModel = new LoadingStageModel();

  constructor(rootStore: IRootStore) {
    this._rootStore = rootStore;
    this._features = {};

    makeObservable<ProjectStore, '_entity' | '_options'>(this, {
      _entity: observable,
      _options: observable,

      load: action,
      reset: action,
      init: action,
      remove: action.bound,

      initialized: computed,
      projectId: computed,
      entity: computed,
      options: computed,
      withPgMessages: computed
    });
  }

  get entity(): ProjectModel | null {
    return this._entity;
  }

  get options(): IProjectOptions | null {
    return this._options;
  }

  get projectId(): string | null {
    return this._entity?.id || null;
  }

  get initialized(): boolean {
    return this._entity !== null && this.loadingStage.successfullyLoaded;
  }

  get withPgMessages(): boolean {
    return this._features[ProjectFeatures.PG_MESSAGES];
  }

  get withCreatePaymentProductsDataFeature(): boolean {
    return this._features[ProjectFeatures.CREATE_PAYMENT_PRODUCTS_DATA];
  }

  init({ project }: { project: ProjectModel }): void {
    this._entity = project;
    this.loadingStage.success();
  }

  async load(projectId: string): Promise<BaseResponse> {
    if (this.loadingStage.isLoading) {
      return {
        isError: true
      };
    }

    this.loadingStage.loading();

    const response = await this._rootStore.networkStore.api<{
      project: GetProjectServer;
    }>(apiUrls.PROJECTS_GET, {
      method: 'GET',
      data: {
        [SmartIds.projectId]: projectId
      },
      errorsMap: mapBaseErrorCodeToProjectErrorToMessage
    });

    if (!response.isError) {
      runInAction(() => {
        this._entity = ProjectModel.fromJson(
          response.data.project,
          this._rootStore
        );
        this._options = {
          extraBlocks: response.data.project.options.extra_blocks
        };

        this._features = response.data.project.features ?? {};
        localStorageHandler.setItem(LocalStorageKey.lastProjectId, projectId);
        this.loadingStage.success();
      });
    } else {
      this._rootStore.routerStore.push(urls.SETTINGS.root);

      runInAction(() => {
        this.loadingStage.error();
      });
    }

    return {
      isError: response.isError
    };
  }

  async remove(): Promise<BaseResponse> {
    if (this.removingStage.isLoading || !this.projectId) {
      return {
        isError: true
      };
    }

    this.removingStage.loading();

    const { isError } = await this._rootStore.networkStore.api(
      apiUrls.PROJECTS_DELETE,
      {
        method: 'POST'
      }
    );

    if (!isError) {
      runInAction(() => {
        this.removingStage.success();
        this.projectId &&
          this._rootStore.projectsStore.projects.removeEntity(this.projectId);
        this._rootStore.routerStore.push(urls.SETTINGS.root);
        this.reset();
      });

      return {
        isError: false
      };
    }

    this.removingStage.error();

    return {
      isError: true
    };
  }

  reset(): void {
    this._entity = null;
    this.loadingStage.reset();
    this.removingStage.reset();
  }
}
