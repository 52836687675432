import convertStringToNumber from './convertStringToNumber';

const getNumberFromStringWithPx = (value: string): number => {
  if (typeof value === 'string' && value.endsWith('px')) {
    return convertStringToNumber(value.replace('px', ''));
  }
  return 0;
};

export default getNumberFromStringWithPx;
