import { TranslationString } from '../localization';
import { BaseResponseCode } from '../network';
import { ScenarioDataServer } from '../scenario';

import {
  ModificationOptionEditingFieldsServer,
  ProductOptionEditingFieldsServer,
  ProductOptionServer
} from './options';

export enum ShopbackProductError {
  TOO_MANY_OPTIONS = 'too_many_options',
  MODIFICATION_ALREADY_EXISTS = 'modification_already_exists'
}

export enum HandleShopbackShopMode {
  edit = 'edit', // редактирование
  create = 'create', //создание
  initialEdit = 'initial_edit' // редактирование после первоначального создания(при возврате с создания категорий "назад")
}

export type HandleShopbackShopParams =
  | { mode: HandleShopbackShopMode.create }
  | { mode: HandleShopbackShopMode.edit; shopId: number }
  | { mode: HandleShopbackShopMode.initialEdit; shopId: number };

export const mapCreatingProjectErrorToMessage =
  (code: BaseResponseCode.notFound): TranslationString =>
  (t) =>
    t(`shopback.errors.projectGet.${code}`, {
      ns: 'entities'
    });

export const mapCreatingProductErrorToMessage =
  (code: ShopbackProductError): TranslationString =>
  (t) =>
    t(`shopback.errors.handleProduct.${code}`, {
      ns: 'entities'
    });

export enum ShopbackCategoryViewMode {
  horizontal = 'horizontal',
  photo = 'photo'
}

export enum ShopbackViewMode {
  large = 'large',
  medium = 'medium',
  small = 'small'
}

export enum ShopbackPreviewMode {
  childCategories = 'childCategories',
  projectCategories = 'projectCategories'
}

export enum CartMode {
  // обычная корзина
  multiple = 'multiple',
  // без корзины
  single = 'single',
  // 1 шт
  oneItem = 'one_item'
}

export enum ShopbackCardImgProportion {
  nineSixteen = 'nine_sixteen',
  fourFive = 'four_five',
  oneOne = 'one_one',
  sixteenNine = 'sixteen_nine'
}

/**
 * Режим обаботки товара (любого типа)
 */
export enum HandleProductPageMode {
  create = 'create',
  edit = 'edit',
  initialCreate = 'initial_create'
}

export const cartModeOrder: CartMode[] = [
  CartMode.multiple,
  CartMode.oneItem,
  CartMode.single
];

export type ShopbackProjectEditingFieldsServer = {
  shopId?: number;
  name: string;
  view_mode: ShopbackViewMode;
  category_view_mode: ShopbackCategoryViewMode;
  show_search: boolean;
  show_category: boolean;
  cart_mode: CartMode;
  support_electronic_goods: boolean;
  main_page_title: string | null;
  cart_title: string | null;
  // текст кнопки для перехода на страницу с корзиной("Перейти в корзину")
  cart_button_title: string | null;
  // текст кнопки для добавления товара в корзину на странице товара ("Добавить в корзину")
  product_call_to_action_title: string | null;
  // текст кнопки для добавления товара в корзину в списке товаров ("Добавить в корзину")
  product_in_list_call_to_action_title: string | null;
  // текст кнопки для совершения оплаты
  finish_button_title: string | null;
  currency: string | null;
  support_modification: boolean;
  align_cards?: boolean;
  card_img_proportion?: ShopbackCardImgProportion | null;
};

export type ShopbackProjectServer = ShopbackProjectEditingFieldsServer & {
  id: number;
  uuid: string;
  smartbot_project_id: string;
  tg_token: string;
};

export type ShopbackAttachmentServer = {
  aid: number;
  author_id: number | null;
  big_height: number;
  big_width: number;
  big_image_url: string;
  created: string;
  small_height: number;
  small_image_url: string;
  small_width: number;
  url: string;
  is_processed: boolean;
  is_valid: boolean;
};

export type ShopbackCategoryCommonEditingFieldsServer = {
  name: string;
  shopId: number;
  attachment: number | null;
  id: number | null;
  child_categories_view_mode: ShopbackCategoryViewMode;
  parent: number | null;
  is_visible: boolean;
  external_id: string | null;
};

export type ShopbackCategoryEditingFieldsServer =
  ShopbackCategoryCommonEditingFieldsServer & {
    parent_ids: number[];
    order: number;
    is_deleted: boolean;
    child_ids: number[] | null;
  };

export type ShopbackCategoryServer = Omit<
  ShopbackCategoryEditingFieldsServer,
  'attachment'
> & {
  id: number;
  project_id: number;
  attachment: ShopbackAttachmentServer | null;
  attachment_id: number | null;
  is_visible: boolean;
  parent_id: number | null;
};

export type ShopbackProductsListServer = {
  count: number;
  products: ShopbackProductServer[];
};

export type ShopbackProductCommonEditingFieldsServer = {
  id?: number;
  name: string;
  project: number;
  external_id: string;
  price: number | null;
  count: number | null;
  description: string;
  category: number | null;
  attachments: number[];
  is_visible: boolean;
};

/**
 * Информация об основном товаре, приходящая внутри модификации.
 * Приходит с бэка
 */
export type ShortMainProductServer = {
  id: number;
  name: string;
};

/**
 * То, что приходит с бэка
 */
export type ShopbackProductServer = Omit<
  ShopbackProductCommonEditingFieldsServer,
  'attachments' | 'category'
> & {
  id: number;
  category_id: number;
  project_id: number;
  category: string;
  attachments: ShopbackAttachmentServer[] | null;
  modificators: ProductOptionServer[];
  has_modifications: boolean;
  parent: ShortMainProductServer | null;
};

/**
 * То, что нужно отправить на бэк при редактировании/создании товара
 */
export type ShopbackProductEditingFieldsServer = Omit<
  ShopbackProductCommonEditingFieldsServer,
  'project' | 'modificators'
> & {
  modificators: (
    | ProductOptionEditingFieldsServer
    | ModificationOptionEditingFieldsServer
  )[];
  parent_id: number | null;
};

export enum ImportShopbackError {
  NO_REQUIRED_COLUMN = 'NO_REQUIRED_COLUMN',
  TOO_HIGH_CATEGORIES_COMPLEXITY = 'TOO_HIGH_CATEGORIES_COMPLEXITY',
  INVALID_CATEGORIES_ORDER = 'INVALID_CATEGORIES_ORDER',
  EMPTY_EXTERNAL_ID = 'EMPTY_EXTERNAL_ID',
  NON_UNIQUE_EXTERNAL_ID = 'NON_UNIQUE_EXTERNAL_ID',
  EMPTY_NAME = 'EMPTY_NAME',
  TOO_MANY_PHOTOS = 'TOO_MANY_PHOTOS',
  INVALID_PRICE = 'INVALID_PRICE',
  INVALID_PHOTO_URL = 'INVALID_PHOTO_URL',
  INVALID_MODIFICATOR_EXTRA_VALUE = 'INVALID_MODIFICATOR_EXTRA_VALUE',
  DUPLICATE_MODIFICATION_OPTION_NAME = 'DUPLICATE_MODIFICATION_OPTION_NAME',
  INVALID_MODIFICATION_OPTIONS = 'INVALID_MODIFICATION_OPTIONS',
  INVALID_MODIFICATOR_PARENT_EXTERNAL_ID_ORDER = 'INVALID_MODIFICATOR_PARENT_EXTERNAL_ID_ORDER',
  TOO_MANY_PRODUCTS = 'TOO_MANY_PRODUCTS',
  EMPTY_OPTIONS = 'EMPTY_OPTIONS',
  DUPLICATE_MODIFICATION_OPTION_VALUES = 'DUPLICATE_MODIFICATION_OPTION_VALUES',
  INVALID_OPTIONS_SEPARATOR = 'INVALID_OPTIONS_SEPARATOR',
  INVALID_PRODUCT_COUNT = 'INVALID_PRODUCT_COUNT',
  NEGATIVE_PRODUCT_COUNT = 'NEGATIVE_PRODUCT_COUNT',
  TOO_HIGH_PRODUCT_COUNT = 'TOO_HIGH_PRODUCT_COUNT',
  INVALID_MODIFICATOR_EXTRA_VALUE_HEX_FORMAT = 'INVALID_MODIFICATOR_EXTRA_VALUE_HEX_FORMAT'
}

export type ImportShopbackErrorPayload =
  | {
      kind: ImportShopbackError.DUPLICATE_MODIFICATION_OPTION_VALUES;
      extra_data: { row_indexes: number[] };
    }
  | {
      kind: ImportShopbackError.EMPTY_OPTIONS;
      // название колонки
      data: string;
      extra_data: { option_name: string };
    }
  | {
      kind: ImportShopbackError.NO_REQUIRED_COLUMN;
      // название колонки
      data: string;
    }
  | {
      kind: ImportShopbackError.TOO_HIGH_CATEGORIES_COMPLEXITY;
      // сколько колонок с категориями всего
      data: number;
    }
  | {
      kind:
        | ImportShopbackError.INVALID_CATEGORIES_ORDER
        | ImportShopbackError.EMPTY_EXTERNAL_ID
        | ImportShopbackError.NON_UNIQUE_EXTERNAL_ID
        | ImportShopbackError.EMPTY_NAME
        | ImportShopbackError.TOO_MANY_PHOTOS
        | ImportShopbackError.INVALID_PRICE
        | ImportShopbackError.INVALID_MODIFICATION_OPTIONS
        | ImportShopbackError.INVALID_MODIFICATOR_EXTRA_VALUE
        | ImportShopbackError.INVALID_PHOTO_URL
        | ImportShopbackError.INVALID_OPTIONS_SEPARATOR
        | ImportShopbackError.INVALID_PRODUCT_COUNT
        | ImportShopbackError.NEGATIVE_PRODUCT_COUNT
        | ImportShopbackError.TOO_HIGH_PRODUCT_COUNT;
      // номер строки
      data: number;
    };

export type ImportShopbackResponse = {
  total_updated: number;
  total_created: number;
  updated_products: string[];
  created_products: string[];
  errors: ImportShopbackErrorPayload[];
};

export type ShopsScenariosDataServer = {
  projects: Record<string, ScenarioDataServer[]>;
};
