import * as React from 'react';

import { TranslationNode } from 'shared/entities/localization';
import {
  TypographySize,
  TypographyType,
  TypographyWeight
} from 'shared/newEntities/components/Typography';
import {
  BaseButtonProps,
  ButtonColor,
  ButtonSize,
  ComponentButtonProps
} from 'shared/newEntities/components/Button';
import { IconSize } from 'shared/entities/components/Icon';

import Typography from '../Typography';
import { Button } from '../buttons';
import { CircledExclamationMarkIcon, EmptyEnvelopeIcon } from '../icons';

import './Stub.modules.scss';

type StubButton = Omit<BaseButtonProps, 'color' | 'size'> &
  ComponentButtonProps;

export type StubProps = {
  title: TranslationNode;
  description?: TranslationNode;
  button?: StubButton;
  isError?: boolean;
  extraNode?: React.ReactNode;
  withIcon?: boolean;
  size?: TypographySize;
  className?: string;
};

const Stub: React.FC<StubProps> = ({
  title,
  description,
  button,
  isError,
  extraNode,
  withIcon,
  size = TypographySize.m,
  className
}) => {
  return (
    <div styleName="container" className={className}>
      <div styleName="content">
        {isError && (
          <CircledExclamationMarkIcon
            styleName="icon error-icon"
            iconSize={IconSize.L}
          />
        )}
        {withIcon && !isError && (
          <EmptyEnvelopeIcon
            styleName="icon"
            iconSize={
              size === TypographySize.l || size === TypographySize.m
                ? IconSize.XXLM
                : IconSize.XXL
            }
          />
        )}
        <Typography
          fontType={TypographyType.text}
          fontWeight={TypographyWeight.heavy}
          fontSize={size}
        >
          {title}
        </Typography>
        {description && (
          <Typography
            styleName="description"
            fontType={TypographyType.text}
            fontSize={TypographySize.s}
          >
            {description}
          </Typography>
        )}
        {extraNode && <div styleName="node">{extraNode}</div>}
        {button && (
          <Button
            styleName="node"
            {...button}
            color={ButtonColor.blue}
            size={ButtonSize.sm}
          />
        )}
      </div>
    </div>
  );
};

export default Stub;
