import { BaseResponseCode } from 'shared/entities/network';
import { TranslationString } from 'shared/entities/localization';

import { ExtraBlocksServer } from '../block/server';
import { Permission } from '../permissions';
import { ProjectManagerRoleType } from '../manager';

export type ProjectServer = {
  _id: string;
  name: string;
  permissions: Permission[];
  role: ProjectManagerRoleType;
};

export type ProjectOptionsServer = {
  extra_blocks: ExtraBlocksServer;
};

export enum ProjectFeatures {
  PG_MESSAGES = 'PG_MESSAGES',
  CREATE_PAYMENT_PRODUCTS_DATA = 'CREATE_PAYMENT_PRODUCTS_DATA'
}

export type GetProjectServer = ProjectServer & {
  options: ProjectOptionsServer;
  features: Record<ProjectFeatures, boolean> | {};
};

export const mapBaseErrorCodeToProjectErrorToMessage =
  (
    code: BaseResponseCode.notFound | BaseResponseCode.forbidden
  ): TranslationString =>
  (t) =>
    t(`projects.errors.getting.${code}`, {
      ns: 'entities'
    });
