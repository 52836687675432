import { MouseEventHandler } from 'react';

import { IconComponent } from 'shared/entities/components/Icon';
import {
  PopupPosition,
  PopupEventType,
  PopupTrigger,
  PopupChildren
} from 'shared/entities/components/Popup';
import { TranslationString } from 'shared/entities/localization';
import { LoadingStage } from 'shared/entities/meta';
import { ComponentType } from 'shared/entities/components/Component';

import { WithMetaProps } from './WithMeta';
import { TagProps } from './Tag';

export type SelectorKey = string | number;

export type SelectorBaseItem<
  T extends string | TranslationString = TranslationString | string,
  D extends string | TranslationString = TranslationString | string
> = {
  title: T;
  description?: D;
  Icon?: IconComponent;
  rightComponent?: {
    Icon: IconComponent;
    reference?: TranslationString;
    action:
      | {
          type: ComponentType.a;
          href: string;
          onClick?: MouseEventHandler<HTMLAnchorElement>;
        }
      | {
          type: ComponentType.link;
          to: string;
          onClick?: MouseEventHandler<HTMLAnchorElement>;
        }
      | { type: ComponentType.svg; onClick: MouseEventHandler<SVGElement> };
  };
  tagProps?: TagProps;
};

export type SelectorBaseProps = {
  position?: PopupPosition;
  on?: PopupEventType;
  closeOnDocumentClick?: boolean;
  withoutBorder?: boolean;
  // одинаковая ширина Trigger и Content
  sameWidth?: boolean;
  trigger: PopupTrigger;
  children: PopupChildren;
  onClose?: () => void;
  disabled?: boolean;
  contentTopNode?: React.ReactNode;
  className?: string;
  triggerSize?: SelectorTriggerSize;
} & WithMetaProps;

export type SelectorItem<
  T extends string | TranslationString = TranslationString | string,
  D extends string | TranslationString = TranslationString | string
> = SelectorBaseItem<T, D> & {
  id: SelectorKey;
};

export type SelectorEntities<
  K extends SelectorKey = SelectorKey,
  T extends string | TranslationString = TranslationString | string
> = Record<K, SelectorItem<T>>;

export type SelectorOptions = {
  keys: SelectorKey[];
  entities: SelectorEntities;
};

export enum SelectorType {
  single = 'single',
  multi = 'multi'
}

export enum SelectorTriggerSize {
  s = 's',
  sm = 'sm',
  m = 'm',
  l = 'l'
}

export enum SelectorSortOrder {
  asc = 'asc',
  desc = 'desc'
}

export type SelectorTopItem = {
  Icon: IconComponent;
  title: TranslationString | string;
  onClick: (onClose: () => void) => void;
};

export type SelectorProps = Omit<SelectorBaseProps, 'trigger' | 'children'> & {
  options: SelectorOptions;
  placeholder?: TranslationString | string;
  pluralize?: (count: number) => TranslationString;
  loadingStage?: LoadingStage;
  sortOrder?: SelectorSortOrder | null;
  title?: TranslationString | string;
  topItem?: SelectorTopItem;
  onRightIconClick?: (key: SelectorKey) => void;
  TriggerIcon?: IconComponent;
  largeOption?: boolean;
  withoutSearch?: boolean;
  withCroppedTitle?: boolean;
  onBlur?: () => void;
} & (
    | {
        type: SelectorType.single;
        value: SelectorKey | null;
        withRemoving?: boolean;
        onChange: (value: SelectorKey | null) => void;
      }
    | {
        type: SelectorType.multi;
        value: SelectorKey[];
        onChange: (value: SelectorKey[]) => void;
        saveKeysOrder?: boolean;
      }
  );
